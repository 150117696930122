<template>
  <div>
    <Table
      :actions="actions"
      :items="$store.getters['transaction/tableData']"
      :pagination="$store.state.transaction.tablePagination"
      :loading="$store.state.transaction.loadingTable"
      :fields="$store.getters['transaction/tableFields']"
      :keyword="$store.state.transaction.tableKeyword"
      :filter="$store.state.transaction.tableFilter"
      :sort="$store.state.transaction.tableSort"
      :tab-options="tabOptions"
      :sort-options="sortOptions"
      search-placeholder="Search supporter, order number, creator"
      @changeFields="changeFields"
      @resetFields="resetFields"
      @filter="filterData"
      @load="loadData"
      @sort="sortData"
      @paginate="paginate"
      @search="search"
      @download="downloadData"
    />
  </div>
</template>

<script>
import Table from '@/layouts/components/Table.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import {
} from 'bootstrap-vue'

export default {
  components: {
    Table,
  },
  data() {
    return {
      // Modal
      approvalModalVisible: false,
      rejectionModalVisible: false,
      refundModalVisible: false,

      selectedData: '',

      // Tabs
      tabOptions: [
        {
          key: 'statuses', label: 'All', default: true,
        },
        {
          key: 'statuses', label: 'Succeeded', value: 'succeeded',
        },
        { key: 'statuses', label: 'Failed', value: 'failed' },
        { key: 'statuses', label: 'Pending', value: 'pending' },
        { key: 'statuses', label: 'Rejected', value: 'rejected' },
        { key: 'statuses', label: 'Refunded', value: 'refund' },
        { key: 'statuses', label: 'Expired', value: 'expired' },
      ],

      // Sort
      sortOptions: [
        {
          key: 'createdAt', direction: 'desc', label: 'Newest', default: true,
        },
        { key: 'createdAt', direction: 'asc', label: 'Oldest' },
      ],

      actions: ['view', 'download'],
    }
  },
  methods: {
    loadData() {
      return this.$store.dispatch('transaction/getWalletTableData', this.$route.params.id)
    },
    resetDetail() {
      this.$store.commit('transaction/SET_DETAIL_DATA', undefined)
    },
    changeFields(val) {
      this.$store.commit('transaction/SET_TABLE_FIELDS', val)
    },
    resetFields() {
      this.$store.commit('transaction/RESET_TABLE_FIELDS')
    },
    filterData(val) {
      this.$store.commit('transaction/SET_TABLE_FILTER', val)
    },
    sortData(val) {
      this.$store.commit('transaction/SET_TABLE_SORT', val)
    },
    paginate(val) {
      this.$store.commit('transaction/SET_TABLE_PAGINATION', val)
    },
    search(val) {
      this.$store.commit('transaction/SET_TABLE_KEYWORD', val)
    },
    downloadData() {
      return this.$store.dispatch('transaction/downloadTableData')
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success',
              icon: 'CheckIcon',
              text: 'Your data will be ready in a minutes!',
              variant: 'success',
            },
          })

          this.$router.push({ name: 'export-result' })
        })
    },
  },
}
</script>
